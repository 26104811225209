
#Innerbody{
	margin-top: 10px;
}

#Popup{
	$width:400px;
	width:$width;
	margin-left: -$width / 2;
	top:$bannerHeightWide+30px;
	font-size: 16px;
}

#Popup.popupAccountMenu{
	width:200px;
	//height:185px;
	right:5px;
	top:$bannerHeightWide+5px;
}

#Popup.medium{
	height:220px;
}
#Popup.small{
	height:120px;
}

.popupOption{
	i{
		line-height: 2.4;
	}
}
