
#Popup{
	$width:400px;
	width:$width;
	margin-left: -$width / 2;
	top:$bannerHeightMid+30px;
	font-size: 16px;
}
#Popup.popupAccountMenu{
	width:180px;
	//height:175px;
	right:5px;
	top:$bannerHeightMid+5px;
}

#Popup.medium{
	height:220px;
}
#Popup.small{
	height:120px;
}

.popupOption{
	i{
		line-height: 2;
	}
}