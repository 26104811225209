@import '~@fortawesome/fontawesome-free/css/solid.min.css';
.fas{
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
}

.fas::before{
	font-style: normal;
}

.fa-user::before {
	content: "\f007";
}
.fa-sliders-h::before{
	content: "\f1de";
}
.fa-sign-out-alt::before{
	content: "\f2f5";
}
.fa-toggle-on::before{
	content: "\f205";
}
.fa-toggle-off::before{
	content: "\f204";
}
.fa-times::before {
   content: "\f00d";
}
.fa-chevron-up::before {
    content: "\f077";
}
.fa-chevron-down::before {
    content: "\f078";
}
.fa-check::before {
    content: "\f00c";
}
.fa-spinner:before {
	content: "\f110";
}
.fa-share-alt:before{
	content: "\f1e0";
}
.fa-flag:before{
	content: "\f024";
}
.fa-ban:before{
	content: "\f05e";
}