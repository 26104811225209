////////////////Wide
@media (min-width:$widthWide){
	$bannerHeight:50px;
	body{
		--bannerHeight:#{$bannerHeight};
	}

	#Banner{
		max-width: $maxAppWidth+250px;
		--flexFillerFlex:0;
		--searchBarFlex:20;
		--bannerIconFlex:1;
		--loginSignupFlex:5;
		--accountFlex:8;
		--bannerPadding:4px 0px;

		.bannerItemContainer{
			--itemContainerPadding:0px 2px;
			--bannerIconFlexBasis:35px;
		}

		.bannerButton{
			--bannerButtonPadding:1px;
			--bannerButtonFontSize:20px;
			.text-centered{
				--textLineHeight:45px;
			}
		}

	}

	#BannerSearch{
		--bannerSearchDisplay: flex;
		#BannerSearchText{
			--bannerSearchTextPadding:0px 10px;
			--bannerSearchTextFontSize: calc((#{$bannerHeight} - 10px) / 1.5);
		}
		//Custom select box from WS3
		.customSelect{
			--bannerCustomSelectWdith:75px;
			--bannerCustomSelectBorder:solid #{$Color5} 1px;
			.select-selected:after{
				--selectSelectedTop:19px;
				--selectSelectedRight:8px;
				--selectSelectedBorderWidth:6px;
			}
			.select-selected.select-arrow-active:after {
				--selectSelectedArrowTop:7px;
			}
			.select-items div,.select-selected {
				--selectItemsBorder:solid #{$Color5} 1px;
				--selectItemsPadding: 6px 5px;
			}
		}
		#BannerSearchSortType{
			border-left:solid #{$Color5} 1px;
		}
		#BannerSearchSubmit{
			--bannerSearchSubmitWidth:45px;
		}
	}


	#InnerBody{
		--topMargin:10px;
	}


}
////////////////Mid
@media (max-width:($widthWide - 1)) and (min-width:$widthNarrow){
	$bannerHeight:40px;
	body{
		--bannerHeight:#{$bannerHeight};
	}

	#Banner{
		--flexFillerFlex:0;
		--searchBarFlex:25;
		--bannerIconFlex:1;
		--loginSignupFlex:6;
		--accountFlex:8;
		--bannerPadding:2px 0px;

		.bannerItemContainer{
			--itemContainerPadding:0px 2px;
			--bannerIconFlexBasis:30px;
		}

		.bannerButton{
			--bannerButtonPadding:1px;
			--bannerButtonFontSize:20px;
			.text-centered{
				--textLineHeight:35px;
			}
		}

	}

	#BannerSearch{
		--bannerSearchDisplay: flex;
		#BannerSearchText{
			--bannerSearchTextPadding:0px 8px;
			--bannerSearchTextFontSize: calc((#{$bannerHeight} - 10px) / 1.5);
		}
		//Custom select box from WS3
		.customSelect{
			--bannerCustomSelectWdith:72px;
			--bannerCustomSelectBorder:solid #{$Color5} 1px;
			.select-selected:after{
				--selectSelectedTop:14px;
				--selectSelectedRight:8px;
				--selectSelectedBorderWidth:6px;
			}
			.select-selected.select-arrow-active:after {
				--selectSelectedArrowTop:3px;
			}
			.select-items div,.select-selected {
				--selectItemsBorder:solid #{$Color5} 1px;
				--selectItemsPadding: 1px 3px;
			}
		}
		#BannerSearchSortType{
			border-left:solid #{$Color5} 1px;
		}
		#BannerSearchSubmit{
			--bannerSearchSubmitWidth:38px;
		}
	}


	#InnerBody{
		--topMargin:10px;
	}

}



//Narrow
@media (max-width:($widthNarrow - 1)){
	$bannerHeight:35px;
	body{
		--bannerHeight:#{$bannerHeight};
	}

	#Banner{
		--flexFillerFlex:6;
		--searchBarFlex:0;
		--bannerIconFlex:1;
		--loginSignupFlex:6;
		--accountFlex:10;
		--bannerPadding:2px 0px;

		.bannerItemContainer{
			--itemContainerPadding:0px 2px;
			--bannerIconFlexBasis:35px;
		}

		.bannerButton{
			--bannerButtonPadding:0px;
			--bannerButtonFontSize:15px;
			.text-centered{
				--textLineHeight:32px;
			}
		}

	}

	#BannerSearch{
		--bannerSearchDisplay: none;
	}

	#InnerBody{
		--topMargin:10px;
	}

}
