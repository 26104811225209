
@import "./header_vars";

body{
	top:var(--bannerHeight);
}

#BannerContainer{
	width: 100%;
	position: fixed;
	top:0;
	background-color: $Color1;
	z-index: 5;
}

#BannerContainer{
	box-shadow:0px 3px 10px 5px rgba(40,40,40,.5);
}

#Banner{
	height:var(--bannerHeight);
	display:flex;
	position:relative;
	top:0;
	width:100%;
	padding:var(--bannerPadding);
	margin-left:auto;
	margin-right: auto;
	z-index: 5;
	background-color: $Color1;
	.flexFiller{
		flex:var(--flexFillerFlex);
	}
}
.bannerItemContainer{
	height:100%;
	padding:var(--itemContainerPadding);
}
.bannerButton{
	display:block;
	height:100%;
	width:100%;
	overflow: hidden;
	cursor: pointer;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	padding:var(--bannerButtonPadding);
	border-radius: $borderRadius;
	font-size: var(--bannerButtonFontSize);
	.text-centered{
		height:100%;
		text-transform: uppercase;
		font-weight: bold;
		line-height: var(--textLineHeight);
	}
}
.bannerButton:hover{
	background-color:$Color2;
}

.bannerButtonImage{
	height: 100%;
	width:auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

#BannerButtonHome{
	background-image: url('../../../images/TheDotDogLogo.svg');
}
#BannerButtonHome:hover{
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}
#BannerButtonUpload{
	background-image: url('../../../images/TheDotDogLogo.svg');
}
#BannerButtonUpload:hover{
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}
#BannerButtonAbout{
	background-image: url('../../../images/TheDotDogLogo.svg');
}
#BannerButtonAbout:hover{
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}
.loadFilledLogo{
	height: 0px;
	width: 0px;
	background-image: url('../../../images/TheDotDogLogoFilled.svg');
}

#BannerHomeContainer,#BannerUploadContainer,#BannerAboutContainer{
	flex:var(--bannerIconFlex);
	flex-basis: var(--bannerIconFlexBasis);
	min-width: var(--bannerIconFlexBasis);
}
#BannerSignupContainer,#BannerLoginContainer,#BannerAccountContainer{
	.bannerButton{
		border: solid white 2px;
		border-radius: $borderRadius;
	}
	.bannerButton:hover{
		color:$Color2;
		background-color:white;
	}
}
#BannerSignupContainer,#BannerLoginContainer{
	flex:var(--loginSignupFlex);
}
#BannerAccountContainer{
	flex:var(--accountFlex);
	margin:0px 5px
}


#BannerSearchContainer{
	flex:var(--searchBarFlex);
}
//The Search bar for the banner
#BannerSearch{
	display: var(--bannerSearchDisplay);
	height: 100%;
	background-color: $Color4;
	border-radius: $borderRadius;

	#BannerSearchText{
		flex:1;
		padding:var(--bannerSearchTextPadding);
		font-size: var(--bannerSearchTextFontSize);
		border:none;
	}
	
	#BannerSearchText:hover{
		border-radius: $borderRadius;
	}

	#BannerSearchSubmit{

		display: block;

		background-image: url("/build/images/searchIcon.svg");
		background-repeat: no-repeat;
		background-size: contain;

		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}
	.bannerSearchInput{
		background-color: transparent;
		color:$Color5;
		font-size: 18px;
	}

	.bannerSearchInput:hover,.select-selected:hover{
		background-color: $Color3;
	}

}

//The Search bar for the banner
#BannerSearch{

	//Custom select box from WS3
	.customSelect{
		position: relative;
		flex-shrink:1;
		width:var(--bannerCustomSelectWdith);
		border-right:var(--bannerCustomSelectBorder);
		select{
			display: none;
		}
		.dropdownArrow{
			position: absolute;
		}
	}
	.select-selected{
		background-color: transparent;
	}

	.select-selected:after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		top:var(--selectSelectedTop);
		right:var(--selectSelectedRight);
		border-width: var(--selectSelectedBorderWidth);
		border-style: solid;
		border-color: #fff transparent transparent transparent;
	}
	.select-selected.select-arrow-active:after {
		top:var(--selectSelectedArrowTop);
		border-color: transparent transparent #fff transparent;
	}
		
		/* style the items (options), including the selected item: */
	.select-items div,.select-selected {
		cursor: pointer;
		padding:var(--selectItemsPadding);
	}
	.select-items {
		div{
			border:var(--selectItemsBorder);
			background-color: $Color4;
		}
		div:hover{
			background-color: $Color3;		
		}
	}
		
		/* Hide the items when the select box is closed: */
	.select-hide {
		display: none;
	}
		
	.same-as-selected {
		display:none;
	}
	#BannerSearchSubmit{
		width: var(--bannerSearchSubmitWidth);
	}
}

#BannerSearch.light{
	background-color: $Color4Light;
	.bannerSearchInput:hover,.select-selected:hover{
		background-color: $Color3Light;
	}
	.select-items {
		div{
			background-color: $Color4Light;
			}
		div:hover{
			background-color: $Color3Light;		
		}
	}
}