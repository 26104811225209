
#Popup{
	$width:300px;
	width:$width;
	margin-left: -$width/2;
	top:$bannerHeightNarrow+10px;
	font-size: 16px;
}

#Popup.popupAccountMenu{
	width:180px;
	//height:185px;
	right:5px;
	top:$bannerHeightNarrow+5px;
}

#Popup.big{
	height:230px;
}

#Popup.medium{
	height:90px;
}

#Popup.small{
	height:75px;
}

.popupOption{
	i{
		line-height: 2.4;
	}
}
